import React, { Suspense } from 'react'
import { useQuery } from 'react-query'

import getStaff from 'shared/api/getStaff'
import getTeams from 'shared/api/getTeams'
import FullScreenLoading from 'shared/components/FullScreenLoading'
import getStaffByTeams from 'shared/utils/getStaffByTeams'

const TeamsAndStaffContext = React.createContext()

const TeamsDataProvider = ({ children }) => {
  const { data } = useQuery(
    'getTeamsAndStaff',
    () => Promise.all([getTeams(), getStaff()]),
    { suspense: true, staleTime: Infinity }
  )

  const [teams, staff] = data
  const staffByTeams = getStaffByTeams(teams, staff)

  return (
    <TeamsAndStaffContext.Provider value={{ teams, staff, staffByTeams }}>
      {children}
    </TeamsAndStaffContext.Provider>
  )
}

export const TeamsAndStaffProvider = ({ children }) => {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <TeamsDataProvider>{children}</TeamsDataProvider>
    </Suspense>
  )
}

export const useTeams = () => {
  const { teams } = React.useContext(TeamsAndStaffContext)

  return teams
}

export const useStaffByTeams = () => {
  const { staffByTeams } = React.useContext(TeamsAndStaffContext)

  return staffByTeams
}
