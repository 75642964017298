import React from 'react'

import getTextContent from './getTextContent'

const useColumnSelectionState = (columns) => {
  const selectableColumns = React.useMemo(
    () => columns.filter(({ alwaysVisible }) => !alwaysVisible),
    [columns]
  )
  const searchableColumns = React.useMemo(
    () =>
      selectableColumns.map((c) => ({
        id: c.id,
        label: getTextContent(c.Header).toLowerCase()
      })),
    [selectableColumns]
  )
  const [columnIdsFiltered, setColumnIdsFiltered] = React.useState(
    selectableColumns.map((c) => c.id)
  )
  const onSearchChange = React.useCallback(
    (query = '') => {
      const filtered = query.trim()
        ? searchableColumns.filter((c) => c.label.includes(query.toLowerCase()))
        : searchableColumns

      setColumnIdsFiltered(filtered.map((c) => c.id))
    },
    [searchableColumns, setColumnIdsFiltered]
  )

  const resetSearch = React.useCallback(
    () => setColumnIdsFiltered(selectableColumns.map((c) => c.id)),
    [selectableColumns, setColumnIdsFiltered]
  )

  return {
    onSearchChange,
    columnIdsFiltered,
    selectableColumns,
    resetSearch: resetSearch,
    visibleColumnCount: selectableColumns.filter((col) => col.isVisible).length
  }
}

export default useColumnSelectionState
