import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import { isNumber } from 'apps/Courses/utils/validationHelper'

import styles from './styles.module.scss'

const CourseEditDisplayBasic = ({ data, campusesOptions, onEditClick }) => {
  return (
    <section aria-label='course basic data read-only'>
      <Row>
        <Col xs={4}>
          <ul aria-labelledby='subject-name-label'>
            <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
              <span id='subject-name-label'>
                <Trans>Subject name</Trans>
              </span>
            </Text>

            <Text
              tag='li'
              type='f4'
              marginTop='tiny'
              fontColor='text-base-darkest'
            >
              {data.subject && data.subject.name}
            </Text>
          </ul>
        </Col>

        <Col xs={8}>
          <ul aria-labelledby='subject-code-label'>
            <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
              <span id='subject-code-label'>
                <Trans>Subject code</Trans>
              </span>
            </Text>

            <Text
              tag='li'
              type='f4'
              marginTop='tiny'
              fontColor='text-base-darkest'
            >
              {data.subject && data.subject.code}
            </Text>
          </ul>
        </Col>
      </Row>
      <Row className='marginTop-default'>
        <Col xs={12}>
          <ul aria-labelledby='name-label'>
            <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
              <span id='name-label'>
                <Trans>Name</Trans>
              </span>
            </Text>

            <Text
              tag='li'
              role='gridcell'
              type='f4'
              marginTop='tiny'
              fontColor='text-base-darkest'
            >
              {data.name || data.default_name}
            </Text>
          </ul>
        </Col>
      </Row>

      {campusesOptions && campusesOptions.length > 0 && (
        <Row className='marginTop-default'>
          <Col xs={12}>
            <ul aria-labelledby='campuses-label'>
              <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
                <span id='campuses-label'>
                  <Trans>Campuses</Trans>
                </span>
              </Text>

              <Text
                tag='li'
                type='f4'
                marginTop='tiny'
                fontColor={
                  data.campuses_names ? 'text-base-darkest' : 'text-base'
                }
              >
                {data.campuses_names || <Trans>Not defined</Trans>}
              </Text>
            </ul>
          </Col>
        </Row>
      )}

      <IfHasSetting settingName='modules.core.submodules.institutions.features.external_id.enable'>
        <Row className='marginTop-default'>
          <Col xs={12}>
            <ul aria-labelledby='external-id-label'>
              <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
                <span id='external-id-label'>
                  <Trans>External ID</Trans>
                </span>
              </Text>

              <Text
                tag='li'
                type='f4'
                marginTop='tiny'
                fontColor={data.external_id ? 'text-base-darkest' : 'text-base'}
                aria-label='external id value'
              >
                {data.external_id || <Trans>Not defined</Trans>}
              </Text>
            </ul>
          </Col>
        </Row>
      </IfHasSetting>

      <Row className='marginTop-default'>
        <Col xs={12}>
          <ul aria-labelledby='owner-label'>
            <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
              <span id='owner-label'>
                <Trans>Owner</Trans>
              </span>
            </Text>

            {data.owner && data.owner.id ? (
              <Text
                tag='li'
                type='f4'
                marginTop='tiny'
                fontColor='text-base-darkest'
              >
                {data.owner.first_name} {data.owner.last_name}
              </Text>
            ) : (
              <Text tag='li' type='f4' fontColor='text-base' marginTop='tiny'>
                <Trans>Not defined</Trans>
              </Text>
            )}
          </ul>
        </Col>
      </Row>

      <Row className='marginTop-default'>
        <Col xs={12}>
          <ul aria-labelledby='price-label'>
            <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
              <span id='price-label'>
                <Trans>Price</Trans>
              </span>
            </Text>

            <Text
              tag='li'
              type='f4'
              marginTop='tiny'
              fontColor={
                isNumber(data.price_amount) ? 'text-base-darkest' : 'text-base'
              }
            >
              {isNumber(data.price_amount) ? (
                `${data.price_amount} ${data.price_currency || ''}`
              ) : (
                <Trans>Not defined</Trans>
              )}
            </Text>
          </ul>
        </Col>
      </Row>

      <div className='marginTop-default'>
        <Button
          size='small'
          aria-label='edit course basics'
          onClick={() => onEditClick(data)}
          className={styles.editButton}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    </section>
  )
}

export default CourseEditDisplayBasic
