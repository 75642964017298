import React from 'react'

import { Table } from '@fullfabric/alma-mater'

const TableRow = ({ row, data, ...props }) => {
  return (
    <Table.Row {...props}>
      {row.cells.map((cell) => {
        const {
          key: cellKey,
          linkTo: getLinkTo,
          ...cellProps
        } = cell.getCellProps()

        const linkTo =
          getLinkTo &&
          getLinkTo({
            value: cell.value,
            row,
            data
          })

        return (
          <Table.Data
            key={cellKey}
            title={
              cell.column.getTitle ? cell.column.getTitle(cell) : cell.value
            }
            linkTo={linkTo}
            {...cellProps}
          >
            {cell.render('Cell')}
          </Table.Data>
        )
      })}
    </Table.Row>
  )
}

export default TableRow
