import { utils } from '@fullfabric/public-api'

export default async function getStaff() {
  const response = await fetch(
    `/api/profiles/profiles/options/staff?include_teams=true`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}
