import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Link, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function ActionsContainer({
  id,
  onSave,
  onSendTestEmail,
  isEmbedded,
  saving
}) {
  const timeoutRef = React.useRef()
  const [t] = useTranslation()
  const [showSave, setSave] = React.useState(false)

  useEffect(() => {
    if (saving) {
      setSave(saving)
    }

    if (showSave && !saving) {
      timeoutRef.current && clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setSave(false)
      }, 2000)
    }
    return () => timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [saving, showSave])

  return (
    <div className={styles.actionsElement}>
      {showSave && (
        <Text type='f5' className={styles.savingProcess}>
          {showSave && saving ? t('Saving') : t('Changes saved')}
        </Text>
      )}
      {!isEmbedded && (
        <Link
          withoutStyles
          href={`/shared_email_templates/${id}/usage`}
          target='_parent'
        >
          <Button size='small'>{t('Usage')}</Button>
        </Link>
      )}
      <Button size='small' onClick={onSendTestEmail}>
        {t('Send test email')}
      </Button>

      <Button size='small' primary onClick={onSave}>
        {t('Save and exit')}
      </Button>
    </div>
  )
}
