// import qs from "qs";

import { utils } from '@fullfabric/public-api'

const getStaffProfiles = async () => {
  // const params = {
  //   rules: [
  //     {
  //       value: "staff",
  //       _backbone_type: "Segments.Models.Rule",
  //       type: "Segments::Rules::Context::PlaysRole",
  //     },
  //   ],
  //   limit: 100,
  // };

  // const response = await fetch(`/api/profiles?${qs.stringify(params)}`, {
  const response = await fetch('/api/profiles/profiles/options/staff', {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getStaffProfiles
