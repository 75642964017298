import { utils } from '@fullfabric/public-api'

const getStaffProfiles = async (includeSupportStaff) => {
  const response = await fetch(
    `/api/profiles/profiles/options/staff${
      includeSupportStaff ? '?include_support_staff=true' : ''
    }`
  )
  return await utils.checkResponse(response)
}

export default getStaffProfiles
