import React from 'react'

import { Row } from '@fullfabric/alma-mater'

import SelectColumnsButton from './SelectColumnsButton'
import TableFilter from './TableFilter'
import TableSearch from './TableSearch'
import isFilterableTable from './utils/isFilterableTable'
import isSearchableTable from './utils/isSearchableTable'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function TableManipulationSection({
  onSearch,
  initialSearch,
  searchPlaceholder,
  onFilterChange,
  selectedFilter,
  filters,
  filterProps = {},
  selectableColumns,
  columns = []
}) {
  const isSearchActive = isSearchableTable({ onSearch })
  const isFilterActive = isFilterableTable({ onFilterChange, filters })
  const isSearchOrColSelectionActive = isSearchActive || selectableColumns

  if (!isSearchOrColSelectionActive && !isFilterActive) {
    return null
  }

  return (
    <div
      className={classNames(
        styles.section,
        isFilterActive && styles.withFilterRow
      )}
      data-testid='search-filter-section'
    >
      {isSearchOrColSelectionActive ? (
        <Row
          nogutter
          justify={isSearchActive ? 'between' : 'end'}
          data-testid='search-and-visibility-row'
        >
          {isSearchActive && (
            <TableSearch
              onSearch={onSearch}
              initialSearch={initialSearch}
              placeholder={searchPlaceholder}
            />
          )}
          {selectableColumns && <SelectColumnsButton columns={columns} />}
        </Row>
      ) : null}

      {isSearchOrColSelectionActive && isFilterActive ? (
        <div
          className={styles.inBetweenSpacing}
          data-testid='between-rows-spacing'
        />
      ) : null}

      {isFilterActive ? (
        <TableFilter
          onFilterChange={onFilterChange}
          selectedFilter={selectedFilter}
          filters={filters}
          {...filterProps}
        />
      ) : null}
    </div>
  )
}
