import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Field, Row, Text } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import CampusesField from 'apps/Courses/components/Fields/Campuses'
import OwnerField from 'apps/Courses/components/Fields/Owner'

import styles from './styles.module.scss'

const ERROR_MESSAGES = {
  not_a_number: <Trans>Must be a number</Trans>,
  required_for_price: <Trans>Currency is required for price</Trans>
}

const CourseEditFormBasic = ({
  data,
  inProgress,
  errors,
  currencyOptions,
  campusesOptions,
  onChange,
  onSaveClick,
  onCancelClick
}) => (
  <section aria-label='course basic data editable'>
    <form aria-label='course basic data form'>
      <Row>
        <Col xs={4}>
          <Text fontColor='text-base' bold>
            <Trans>Subject name</Trans>
          </Text>

          <Text type='f4' marginTop='tiny'>
            {data.subject?.name}
          </Text>
        </Col>

        <Col xs={8}>
          <Text fontColor='text-base' bold>
            <Trans>Subject code</Trans>
          </Text>

          <Text type='f4' marginTop='tiny'>
            {data.subject?.code}
          </Text>
        </Col>
      </Row>

      <Row className='marginTop-default'>
        <Col xs={12}>
          <Field
            id='name'
            type='text'
            label={<Trans>Name</Trans>}
            help={
              <Trans>
                The course name is automatically populated with the class starts
                on date if the course name is empty.
              </Trans>
            }
            inputOptions={{
              placeholder: data.name ? '' : data.default_name
            }}
            value={data.name}
            onChange={onChange}
          />
        </Col>
      </Row>

      {campusesOptions && campusesOptions.length > 0 && (
        <Row>
          <Col xs={12}>
            <CampusesField
              onChange={onChange}
              campusesList={campusesOptions}
              values={data.campus_ids}
            />
          </Col>
        </Row>
      )}

      <IfHasSetting settingName='modules.core.submodules.institutions.features.external_id.enable'>
        <Field
          id='external_id'
          type='text'
          label={<Trans>External ID</Trans>}
          value={data.external_id}
          onChange={onChange}
        />
      </IfHasSetting>

      <Row>
        <Col xs={12}>
          <OwnerField onChange={onChange} value={data.owner_id} />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Field
            id='price_amount'
            type='text'
            label={<Trans>Price amount</Trans>}
            value={data.price_amount}
            error={ERROR_MESSAGES[errors.price_amount]}
            onChange={onChange}
          />
        </Col>

        <Col xs={6}>
          <Field
            id='price_currency'
            type='dropdown'
            label={<Trans>Price currency</Trans>}
            inputOptions={{
              placeholder: <Trans>- Select -</Trans>,
              options: currencyOptions
            }}
            value={data.price_currency}
            error={ERROR_MESSAGES[errors.price_currency]}
            onChange={onChange}
          />
        </Col>
      </Row>
    </form>

    <div className='marginTop-default'>
      <Button primary size='small' loading={inProgress} onClick={onSaveClick}>
        <Trans>Save changes</Trans>
      </Button>

      <Button
        size='small'
        onClick={onCancelClick}
        className={styles.cancelButton}
      >
        <Trans>Cancel</Trans>
      </Button>
    </div>
  </section>
)

export default CourseEditFormBasic
