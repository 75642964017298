import React, { useCallback, useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import createEmailTemplate from 'shared/api/createEmailTemplate'

import styles from './styles.module.scss'

export default function CreationModal({ isOpen, handleClose }) {
  const { t } = useTranslation()

  const [isCreating, setIsCreating] = useState(false)
  const [submissionError, setSubmissionError] = useState('')
  const { register, handleSubmit, formState, reset, setFocus, control } =
    useForm()
  const { errors } = formState
  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t("Can't be blank")
  })
  const { ref: subjectInputRef, ...subjectInputProps } = register(
    'default_subject',
    { required: t("Can't be blank") }
  )

  const {
    field: { ref: emailBuilderInputRef, ...emailBuilderInputProps }
  } = useController({
    name: 'email_builder',
    control,
    rules: { required: t("Can't be blank") },
    defaultValue: 'classic'
  })

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const onSubmit = useCallback(
    async (formValues) => {
      try {
        setIsCreating(true)
        const createdSubject = await createEmailTemplate(formValues)

        const url = createdSubject.unlayer_editor
          ? `/shared_email_templates/${createdSubject.id}`
          : `/email_templates#${createdSubject.id}/details`

        window.top.location.assign(url)
      } catch (e) {
        setIsCreating(false)
        setSubmissionError(
          t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )
        )
      }
    },
    [t]
  )

  const emailBuilderOptions = [
    {
      label: t('New Builder'),
      value: 'unlayer',
      instructions: t('Beta version')
    },
    { label: t('Classic Builder'), value: 'classic' }
  ]

  const closeModal = () => {
    handleClose()
    reset()
  }

  return (
    <Modal
      role='dialog'
      aria-labelledby='creation-modal-title'
      isOpen={isOpen}
      size='small'
      header={
        <ModalTitle
          title={
            <span id='creation-modal-title'>
              {t('Create a new email template')}
            </span>
          }
          onClose={closeModal}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={closeModal} disabled={isCreating}>
            {t('Cancel')}
          </Button>

          <Button
            primary
            type='submit'
            form='creation-modal-form'
            onClick={handleSubmit(onSubmit)}
            loading={isCreating}
          >
            {t('Create template')}
          </Button>
        </ModalButtons>
      }
    >
      <form id='creation-modal-form'>
        <IfHasSetting settingName='modules.core.submodules.emails.features.unlayer_editor.enable'>
          <Field
            {...emailBuilderInputProps}
            inputRef={emailBuilderInputRef}
            type='radio'
            label={t('Email Builder')}
            error={errors.email_builder?.message}
            inputOptions={emailBuilderOptions}
          />
        </IfHasSetting>

        <Field
          {...nameInputProps}
          inputRef={nameInputRef}
          type='text'
          label={t('Name')}
          error={errors.name?.message}
        />

        <Field
          {...subjectInputProps}
          inputRef={subjectInputRef}
          type='text'
          label={t('Default subject')}
          error={errors.default_subject?.message}
          className={styles.lastField}
        />
      </form>

      {submissionError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {submissionError}
        </Text>
      ) : null}
    </Modal>
  )
}
