import React from 'react'
import { useTranslation } from 'react-i18next'

import { capitalize, startCase } from 'lodash'
import moment from 'moment'

import {
  AuthorizationOfficer,
  useCurrentUser,
  useSettings
} from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import Plural from 'shared/components/Plural'
import { useStaffByTeams, useTeams } from 'shared/contexts/TeamsAndStaffContext'
import sortStrings from 'shared/utils/sortStrings'

import CreatedByCell from './CreatedByCell'
import MoreOptionsCell from './MoreOptionsCell'

const FILTER_STORAGE_KEY = 'shared_email_templates:filter:'

export default function EmailTemplatesTable() {
  const teams = useTeams()
  const staffByTeams = useStaffByTeams()
  const user = useCurrentUser()
  const { t, i18n } = useTranslation()
  const settings = useSettings()

  const isUnlayerEnabled = AuthorizationOfficer.moduleHasFeature(
    'core.emails',
    'unlayer_editor',
    settings
  )

  return (
    <AutoloadDataTable
      fixedLayout
      virtualized
      onRowClick={({ rowData }) => {
        window.top.location.assign(`/email_templates#${rowData.id}/editor`)
      }}
      fetchURL={'/api/email_templates/shared/tabular'}
      reactQueryProps={{
        staleTime: 0
      }}
      emptyMessage={t('No data')}
      columns={[
        {
          Header: t('Name'),
          accessor: 'name',
          width: 360,
          type: 'primary',
          linkTo: ({ row, data }) => ({
            href: isUnlayerEnabled
              ? `/shared_email_templates/${data[row.index].id}`
              : data[row.index].unlayer_editor
              ? `/shared_email_templates/${data[row.index].id}`
              : `/email_templates#${data[row.index].id}/editor`,
            target: '_parent'
          })
        },
        {
          Header: t('Usage'),
          accessor: 'usage',
          width: 100,
          Cell: ({ value }) => (
            <Plural singular={t('Place')} plural={t('Places')} count={value} />
          ),
          linkTo: ({ row, data }) => ({
            href: `/shared_email_templates/${data[row.index].id}/usage`,
            target: '_parent'
          })
        },
        {
          Header: t('Created by'),
          accessor: 'created_by_name',
          width: 160,
          Cell: CreatedByCell
        },
        {
          Header: t('Created at'),
          accessor: 'created_at',
          width: 260,
          Cell: ({ value }) => {
            const createdAtMoment = value
              ? moment(value).locale(i18n.language)
              : null

            return createdAtMoment
              ? createdAtMoment.format('ddd DD MMM, YYYY hh:mm a')
              : ''
          }
        },
        {
          Header: t('Subject'),
          accessor: 'default_subject',
          width: 360
        },
        {
          Header: t('Type'),
          accessor: 'layout._type',
          disableSortBy: true,
          width: 160,
          Cell: ({ row, value }) => {
            if (!value) {
              return ''
            }

            if (
              value === 'Emails::Templates::Layouts::HTML::Revolvapp' ||
              row.original.unlayer_editor
            ) {
              return 'New builder'
            }

            return capitalize(startCase(value.split('::').pop()))
          }
        },
        {
          Header: '',
          accessor: 'id',
          width: 60,
          disableSortBy: true,
          Cell: MoreOptionsCell
        }
      ]}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={(response) => {
        const pagination = response.headers['x-fullfabric-json']
          ? JSON.parse(response.headers['x-fullfabric-json'])
          : {}

        return { ...pagination, data: response.data }
      }}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'created_at',
        'sort[direction]': -1
      }}
      searchable
      filterable
      filterStorageKey={`${FILTER_STORAGE_KEY}${user.id}`}
      filters={[
        {
          id: 'created_by',
          name: 'Created by',
          type: 'single',
          defaultValue: 'teams',
          labelValueSeparator: '',
          searchField: 'label',
          loadOptions: async () => {
            const teamsAsOptions = teams
              .map((t) => ({ label: t.name, value: t.id }))
              .sort((a, b) => sortStrings(a.label, b.label))

            const staffByTeamsAsSections = teamsAsOptions.reduce(
              (sections, teamOption) => {
                const staffInTheTeam = staffByTeams[teamOption.value]

                if (!staffInTheTeam?.length) {
                  return sections
                }

                return [
                  ...sections,
                  {
                    name: teamOption.label,
                    options: staffInTheTeam
                  }
                ]
              },
              []
            )

            return [
              {
                name: 'Quick filters',
                options: [
                  {
                    label: `Me (${user?.first_name} ${user?.last_name})`,
                    value: user?.id
                  },
                  { label: 'My teams', value: 'teams' },
                  { label: 'Anyone', value: 'all' }
                ]
              },
              {
                name: 'By team',
                options: teamsAsOptions
              },
              ...staffByTeamsAsSections
            ]
          }
        }
      ]}
    />
  )
}
