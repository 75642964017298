import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const PathsDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()
  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sectionsBeingEdited) => ({
      ...sectionsBeingEdited,
      paths: true
    }))
  }, [setSectionBeingEdited])

  return (
    <CardContent
      title={<Trans>Path</Trans>}
      actions={
        <Button size='small' onClick={onEditClick}>
          <Trans>Edit</Trans>
        </Button>
      }
    >
      <CardInfo
        main={
          studyPlan.multiple_paths ? (
            <Trans>Multiple paths</Trans>
          ) : (
            <Trans>Single path</Trans>
          )
        }
        secondary={
          studyPlan.multiple_paths
            ? (studyPlan.paths || []).map((path) => path.name).join(', ')
            : null
        }
      />
    </CardContent>
  )
}

export default PathsDisplay
