import React, { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import { isEmpty } from 'lodash-es'

import { Alert, Button, Row, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import api from 'apps/ContentPages/api'
import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import getOverdueFees from 'apps/ContentPages/services/payments/get-overdue-fees'
import getPayableFees from 'apps/ContentPages/services/payments/get-payable-fees'
import { useOpenPaymentsDrawer } from '../../contexts/payments-drawer'
import FeesTable from '../fees-table'
import PayableDiscounts from '../payable-discounts'
import ConfirmationModal from './applic-payment-card/confirmation-modal'

import classNames from 'classnames'
import styles from './applic-payment-card/styles.module.scss'

const ApplicPaymentCard = ({ template, applic, payable }) => {
  const queryClient = useQueryClient()
  const isMobileLayout = useIsMobileLayout()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const user = useCurrentUser()

  const { data: profile } = useQuery(['get-profile', user.id], () =>
    api.getProfile(user.id)
  )

  const openPaymentsDrawer = useOpenPaymentsDrawer(applic.payment_gateway_type)
  const triggerRefetch = useCallback(() => {
    queryClient.invalidateQueries(['get-payment-plan', user.id])
    queryClient.invalidateQueries(['get-applic', applic.id])
  }, [queryClient, user.id, applic.id])

  const requiresDigitalSignature = template.require_digital_signature
  const requiresConfirmation = template.confirmation_enabled

  const onSubmitClick = () => {
    if (!requiresConfirmation) {
      return submitApplication()
    }
    setConfirmationModalOpen(true)
  }

  const submitApplication = async () => {
    setIsSubmitting(true)
    try {
      if (requiresDigitalSignature) {
        const response = await api.signApplication({
          applicId: applic.id
        })

        window.location.assign(response.signing_url)
      } else {
        await api.submitApplication({
          applicId: applic.id
        })

        window.location.assign(`/applications/${applic.id}/submitted`)
      }
    } catch (error) {
      setIsSubmitting(false)
      console.error('error', error)
    }
  }

  const canSubmit = isEmpty(getPayableFees(payable))
  const overdueFees = getOverdueFees(payable)
  const isSubmitted = applic.state === 'submitted'
  const allowDiscountCodes =
    template.allow_discount_codes && !applic.custom_payment_plan_id
  const applicType = template.type

  return (
    <>
      {canSubmit && <Alert success title={<Trans>All fees paid.</Trans>} />}
      <div className={styles.infoContainer}>
        <div
          className={classNames(styles.header, isMobileLayout && styles.mobile)}
        >
          <Row nogutter justify='start'>
            <Text tag='span' type='h3' fontColor='text-base-darkest'>
              {payable.context_long_name}
            </Text>
          </Row>
          <Row nogutter>
            <Text type='f4' fontColor='text-base-darker'>
              {profile?.full_name}
            </Text>
          </Row>
        </div>
        {!isEmpty(overdueFees) && (
          <FeesTable
            fees={overdueFees}
            paymentPlan={payable}
            discounts={payable.discounts}
            opts={{
              onFeeClick: openPaymentsDrawer,
              hideDueDateColumn: true,
              disableClickIfPaid: true,
              hideSecondaryFeeStatuses: true
            }}
          />
        )}
      </div>
      {!canSubmit && allowDiscountCodes && (
        <PayableDiscounts payable={payable} triggerRefetch={triggerRefetch} />
      )}
      {canSubmit && (
        <>
          <ConfirmationModal
            isOpen={confirmationModalOpen}
            onClose={() => setConfirmationModalOpen(false)}
            onSubmit={submitApplication}
            template={template}
          />
          <div className={styles.containerRow}>
            <Text fontColor='text-base'>
              <Trans>Next step.</Trans>
            </Text>
            <Text bold fontColor='text-base-darkest'>
              {applicType === 'application' ? (
                <Trans>
                  Finish your application by clicking the button below.
                </Trans>
              ) : (
                <Trans>
                  Finish your registration by clicking the button below.
                </Trans>
              )}
            </Text>
          </div>
          {isSubmitted ? (
            <Button primary disabled className={styles.submitBtn}>
              <Trans>Submitted</Trans>
            </Button>
          ) : (
            <Button
              primary
              onClick={onSubmitClick}
              loading={isSubmitting}
              className={styles.submitBtn}
            >
              {requiresDigitalSignature ? (
                <Trans>Sign & Submit</Trans>
              ) : (
                <Trans>Submit</Trans>
              )}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default ApplicPaymentCard
