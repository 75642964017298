import React from 'react'

import { isEmpty } from 'lodash-es'

import { OneColumnLayout } from '@fullfabric/alma-mater'

import StudyPlanCreationModal from 'apps/StudyPlans/components/StudyPlanCreationModal'
import SubjectGroupsList from 'apps/StudyPlans/components/SubjectGroupsList'
import { SelectedPathProvider } from 'apps/StudyPlans/contexts/SelectedPath'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import PageHeader from './PageHeader'

const SubjectsPage = () => {
  const studyPlan = useStudyPlan()

  return (
    <OneColumnLayout pageHead={<PageHeader />}>
      {isEmpty(studyPlan) ? (
        <StudyPlanCreationModal />
      ) : (
        <SelectedPathProvider>
          <SubjectGroupsList />
        </SelectedPathProvider>
      )}
    </OneColumnLayout>
  )
}

export default SubjectsPage
