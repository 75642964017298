import capitalize from 'lodash/capitalize'
import words from 'lodash/words'

import { utils } from '@fullfabric/public-api'

const getLifecyclesAsOptions = async () => {
  const response = await fetch('/api/lifecycles', {
    credentials: 'same-origin'
  })
  const result = await utils.checkResponse(response)

  return lifecyclesToOptions(result.states)
}

const IGNORED_STATES = ['staff', 'admin', 'contact', 'lecturer', 'user']

export const lifecyclesToOptions = (lifecycleStates) =>
  lifecycleStates.reduce((acc, state) => {
    if (IGNORED_STATES.includes(state.name)) {
      return acc
    }

    const sortedSubstates = [...state.substates].sort(
      (ssA, ssB) => ssA.order - ssB.order
    )

    return [
      ...acc,
      ...sortedSubstates.map((substate) => ({
        value: `${state.name}::${substate.name}`,
        label: getLifecycleLabel(state.name, substate.name)
      }))
    ]
  }, [])

const getLifecycleLabel = (state, substate) => {
  const stateLabel = capitalize(state)
  const substateLabel = capitalize(words(substate).join(' '))

  return `${stateLabel} - ${substateLabel}`
}

export default getLifecyclesAsOptions
