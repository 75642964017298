import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ArrowLeftIcon,
  EditIcon,
  IconButton,
  PageHead,
  Text
} from '@fullfabric/alma-mater'

import ActionsContainer from './ActionsContainer'
import EditContainer from './EditContainer'
import SendTestEmailModal from './SendTestEmailModal'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function EditorHeader({
  id,
  title,
  subject,
  refetch,
  saving,
  customTitle,
  isEmbedded,
  onClickBack
}) {
  const [t] = useTranslation()
  const [editField, setEditField] = useState()
  const [sendTestEmail, setSendTestEmail] = useState(false)

  const onSaveAndExit = useCallback(
    (ev) => {
      ev?.preventDefault()

      if (!saving) {
        if (onClickBack) {
          onClickBack()
        } else {
          window.top.location.assign('/shared_email_templates')
        }
      }
    },
    [onClickBack, saving]
  )

  const titleContainer = editField ? (
    <EditContainer
      id={id}
      value={editField === 'name' ? title : subject}
      editField={editField}
      onCancel={() => setEditField(undefined)}
      onSave={refetch}
    />
  ) : (
    <div className={styles.textContainer}>
      <Text bold type='h4' tag='span'>
        {customTitle?.type || t('Email Template:')}
      </Text>
      <Text bold type='h4' tag='span' fontColor='text-base-darkest'>
        {customTitle?.name || title}
      </Text>
      {!isEmbedded && (
        <IconButton
          data-testid='title-edit'
          plain
          onClick={() => setEditField('name')}
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  )

  return (
    <>
      <SendTestEmailModal
        id={id}
        isOpen={sendTestEmail}
        onClose={() => setSendTestEmail(false)}
      />
      <PageHead
        inline
        title={titleContainer}
        titleAs='div'
        className={classNames(styles.pageHead, {
          [styles.embedded]: isEmbedded
        })}
        breadcrumbs={
          editField
            ? []
            : [
                {
                  icon: ArrowLeftIcon,
                  ...(onSaveAndExit
                    ? { onClick: onSaveAndExit }
                    : {
                        href: '/shared_email_templates',
                        target: '_parent'
                      })
                }
              ]
        }
        secondaryInfo={
          !isEmbedded &&
          !editField && (
            <div className={styles.textContainer}>
              <Text bold type='f5' tag='span'>
                {t('Subject:')}
              </Text>
              <Text bold type='f5' tag='span' fontColor='text-base-darkest'>
                {subject}
              </Text>
              <IconButton
                data-testid='subject-edit'
                plain
                onClick={() => setEditField('default_subject')}
              >
                <EditIcon />
              </IconButton>
            </div>
          )
        }
        action={
          <ActionsContainer
            id={id}
            isEmbedded={isEmbedded}
            saving={saving}
            onSave={onSaveAndExit}
            onSendTestEmail={() => setSendTestEmail(true)}
          />
        }
      />
    </>
  )
}
