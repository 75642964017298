import { utils } from '@fullfabric/public-api'

const getStaffProfiles = async () => {
  const response = await fetch('/api/profiles/profiles/options/staff', {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getStaffProfiles
