import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import PathsDisplay from './Display'
import PathsForm from './Form'

const PathsSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Paths</Trans>}
      description={
        <Trans>Define the type of path and their respective names.</Trans>
      }
    >
      {sectionsBeingEdited.paths ? <PathsForm /> : <PathsDisplay />}
    </AnnotatedLayout.Section>
  )
}

export default PathsSection
