import { utils } from '@fullfabric/public-api'

export default async function createEmailTemplate(data) {
  const response = await fetch('/api/email_templates/', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      _type: 'Emails::Templates::Shared',
      name: data.name,
      default_subject: data.default_subject,
      unlayer_editor: data.email_builder === 'unlayer'
    })
  })

  return await utils.checkResponse(response)
}
