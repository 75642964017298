import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import { Button, Link } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import WidgetContainer from 'apps/ContentPages/components/widget-container'
import WidgetSection from 'apps/ContentPages/components/widget-section'
import { formatCurrency } from 'apps/ContentPages/utils/format-price'
import getProductDuration from 'apps/ContentPages/utils/get-product-duration'
import getProductTargetApplicTemplate from 'apps/ContentPages/utils/get-product-target-applic-template'
import getStartApplicationLink from 'apps/ContentPages/utils/get-start-application-link'
import LabelValuePair from './label-value-pair'
import Placeholder from './placeholder'

import styles from './styles.module.scss'

const ProductCatalogDetailWidget = ({ product, isLoading }) => {
  const { locale } = useLocale()
  const applicationTemplate = getProductTargetApplicTemplate(product)
  const hasARegistrationForm = applicationTemplate?.type === 'registration'

  const hrefToApplic = getStartApplicationLink(product, applicationTemplate)

  return (
    <div role={'region'} aria-label='information'>
      <WidgetContainer>
        <WidgetSection single>
          {isLoading ? (
            <Placeholder />
          ) : (
            <>
              <Link
                withoutStyles
                href={hrefToApplic}
                aria-labelledby='register-button'
                className={styles.registerButtonWrapper}
              >
                <Button
                  id='register-button'
                  primary
                  className={styles.registerButton}
                >
                  {hasARegistrationForm ? (
                    <Trans>Register</Trans>
                  ) : (
                    <Trans>Apply</Trans>
                  )}
                </Button>
              </Link>
              <dl
                aria-label='information list'
                className={styles.listContainer}
              >
                <LabelValuePair
                  label={<Trans>Start date</Trans>}
                  value={
                    product?.starts_on &&
                    moment(product?.starts_on).format('MMM D, yyyy')
                  }
                />
                <LabelValuePair
                  label={<Trans>End date</Trans>}
                  value={
                    product?.ends_on &&
                    moment(product?.ends_on).format('MMM D, yyyy')
                  }
                />
                <LabelValuePair
                  label={<Trans>Duration</Trans>}
                  value={getProductDuration(
                    product?.starts_on,
                    product?.ends_on
                  )}
                />
                <LabelValuePair
                  label={
                    hasARegistrationForm ? (
                      <Trans>Registration deadline</Trans>
                    ) : (
                      <Trans>Application deadline</Trans>
                    )
                  }
                  value={
                    product?.closes_on &&
                    moment(product.closes_on).format('MMM D, yyyy')
                  }
                />
                <LabelValuePair
                  label={<Trans>Location</Trans>}
                  value={
                    !!product?.campuses?.length &&
                    product.campuses.map((campus) => campus.name).join(', ')
                  }
                />
                <LabelValuePair
                  label={<Trans>Price</Trans>}
                  value={
                    product?.price_amount &&
                    product?.price_currency &&
                    formatCurrency(
                      product.price_amount,
                      product.price_currency,
                      locale
                    )
                  }
                />
                <div className={styles.negativeSpace} />
              </dl>
            </>
          )}
        </WidgetSection>
      </WidgetContainer>
    </div>
  )
}

export default ProductCatalogDetailWidget
