import React from 'react'
import { useMutation } from 'react-query'

import updateStudyPlan from 'apps/StudyPlans/api/updateStudyPlan'
import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useSetStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  getPathPlaceholder,
  needsConfirmationToDeletePaths,
  validatePaths
} from '../utils'

const usePathSaveChanges = ({
  multiplePaths,
  paths,
  studyPlan,
  setPaths,
  setConfirmModalOpen
}) => {
  const setStudyPlan = useSetStudyPlan()
  const onChangesSaved = useOnChangesSaved()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()

  const { mutate: updatePathsMutation, isLoading } = useMutation(
    ({ studyPlanId, multiplePaths, paths }) => {
      return updateStudyPlan(studyPlanId, {
        multiple_paths: multiplePaths,
        paths
      })
    },
    {
      onSuccess: (updatedStudyPlan) => {
        onChangesSaved()
        setStudyPlan({ ...studyPlan, ...updatedStudyPlan })
        setSectionsBeingEdited((sections) => ({
          ...sections,
          paths: false
        }))
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    const [pathsAfterValidation, hasErrors] = validatePaths(paths)
    if (hasErrors) {
      setPaths(pathsAfterValidation)
      return
    }

    if (needsConfirmationToDeletePaths(multiplePaths, studyPlan)) {
      setConfirmModalOpen(true)
      return
    }

    updatePathsMutation({
      studyPlanId: studyPlan.id,
      multiplePaths,
      paths: Object.values(paths).map((path, idx) => ({
        ...path,
        order: idx + 1
      }))
    })
  }, [
    multiplePaths,
    paths,
    studyPlan,
    updatePathsMutation,
    setConfirmModalOpen,
    setPaths
  ])

  const onSaveChangesModalConfirm = React.useCallback(() => {
    updatePathsMutation({
      studyPlanId: studyPlan.id,
      multiplePaths,
      paths: Object.values(paths).map((path, idx) => ({
        ...path,
        order: idx + 1
      }))
    })
  }, [updatePathsMutation, studyPlan, multiplePaths, paths])

  const onCancel = React.useCallback(() => {
    setPaths(
      (studyPlan.paths || []).length === 0
        ? getPathPlaceholder()
        : studyPlan.paths.reduce(
            (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
            {}
          )
    )
    setSectionsBeingEdited((sections) => ({
      ...sections,
      paths: false
    }))
  }, [setSectionsBeingEdited, setPaths, studyPlan])

  return {
    isLoading,
    onSaveChanges,
    onSaveChangesModalConfirm,
    onCancel
  }
}

export default usePathSaveChanges
