import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import * as LoginRoutePaths from 'apps/Authentication/constants/routes'
import * as AllContentPageRoutePaths from 'apps/ContentPages/components/app-routes/constants'
import { SHARED_EMAIL_TEMPLATES_ROUTE } from 'apps/EmailTemplates/constants'
import * as EvaluationsAppRoutesPaths from 'apps/Evaluations/components/AppRoutes/constants'
import { BASE_EVENTS_ROUTE } from 'apps/Events/components/AppRoutes/constants'
import * as FinancialAppRoutePaths from 'apps/Financial/components/AppRoutes/constants'
import * as ImportAppRoutePaths from 'apps/Imports/components/AppRoutes/constants'
import { HOME_ROUTE as INBOX_HOME_ROUTE } from 'apps/Inbox/components/AppRoutes/constants'
import { MERGE_PROFILES_ROUTE } from 'apps/MergeProfiles/components/AppRoutes/constants'
import { BASE_REPORTS_APP_ROUTES } from 'apps/Reports/components/AppRoutes/constants'

// separate hard coded content page paths, like payments and forms, from the
// catch-all path to match any custom pages created by the client
const { STUDENT_HUB_PAGE_ROUTE: aCustomPath, ...ContentPageRoutePaths } =
  AllContentPageRoutePaths

const ApplicsAppRoutes = React.lazy(() =>
  import('apps/Applics/components/ApplicAppRoutes')
)
const ApplicTemplatesAppRoutes = React.lazy(() =>
  import('apps/Applics/components/ApplicTemplateAppRoutes')
)
const LoginAppRoutes = React.lazy(() =>
  import('apps/Authentication/components/AppRoutes')
)
const BillingAppRoutes = React.lazy(() =>
  import('apps/Billing/components/AppRoutes')
)
const ContentPagesAppRoutes = React.lazy(() =>
  import('apps/ContentPages/components/app-routes')
)
const CoursesAppRoutes = React.lazy(() =>
  import('apps/Courses/components/AppRoutes')
)
const EmailTemplateRoutes = React.lazy(() => import('apps/EmailTemplates'))
const EvaluationsAppRoutes = React.lazy(() =>
  import('apps/Evaluations/components/AppRoutes')
)
const EventsAppRoutes = React.lazy(() =>
  import('apps/Events/components/AppRoutes')
)
const FinancialAppRoutes = React.lazy(() =>
  import('apps/Financial/components/AppRoutes')
)
const ImportAppRoutes = React.lazy(() =>
  import('apps/Imports/components/AppRoutes')
)
const InboxAppRoutes = React.lazy(() =>
  import('apps/Inbox/components/AppRoutes')
)
const MergeProfilesAppRoutes = React.lazy(() =>
  import('apps/MergeProfiles/components/AppRoutes')
)
const PoliciesAppRoutes = React.lazy(() =>
  import('apps/Policies/components/AppRoutes')
)
const ReportsAppRoutes = React.lazy(() =>
  import('apps/Reports/components/AppRoutes')
)
const SegmentsAppRoutes = React.lazy(() =>
  import('apps/Segments/components/AppRoutes')
)
const StudyPlanAppRoutes = React.lazy(() =>
  import('apps/StudyPlans/components/AppRoutes')
)
const SubjectsAppRoutes = React.lazy(() =>
  import('apps/Subjects/components/AppRoutes')
)

export default function AppsRoutes() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    params.delete('iframe')

    const paramsString = params.toString() ? `?${params.toString()}` : ''

    window.top?.history &&
      window.top.history.replaceState({}, '', `${pathname}${paramsString}`)
  }, [pathname])

  return (
    <Switch>
      {/* <LoginAppRoutes /> */}
      <Route path={Object.values(LoginRoutePaths)} component={LoginAppRoutes} />
      {/* <EmailTemplateRoutes /> */}
      <Route
        path={SHARED_EMAIL_TEMPLATES_ROUTE}
        component={EmailTemplateRoutes}
      />
      {/* <ImportAppRoutes /> */}
      <Route
        path={Object.values(ImportAppRoutePaths)}
        component={ImportAppRoutes}
      />

      {/* <EvaluationsAppRoutes /> */}
      <Route
        path={Object.values(EvaluationsAppRoutesPaths)}
        component={EvaluationsAppRoutes}
      />

      {/* <InboxAppRoutes /> */}
      <Route path={INBOX_HOME_ROUTE} component={InboxAppRoutes} />

      {/* <FinancialAppRoutes /> */}
      <Route
        path={Object.values(FinancialAppRoutePaths)}
        component={FinancialAppRoutes}
      />

      {/* <MergeProfilesAppRoutes /> */}
      <Route path={MERGE_PROFILES_ROUTE} component={MergeProfilesAppRoutes} />

      {/* <EventsAppRoutes /> */}
      <Route path={BASE_EVENTS_ROUTE} component={EventsAppRoutes} />

      {/* <PoliciesAppRoutes /> */}
      <Route path='/policies' component={PoliciesAppRoutes} />

      {/* <CoursesAppRoutes /> */}
      <Route
        path='/institutions/settings/courses'
        component={CoursesAppRoutes}
      />

      {/* <BillingAppRoutes /> */}
      <Route path='/billing' component={BillingAppRoutes} />

      {/* <ReportsAppRoutes /> */}
      <Route path={BASE_REPORTS_APP_ROUTES} component={ReportsAppRoutes} />

      {/* <ApplicTemplatesAppRoutes /> */}
      <Route path='/templates/new' component={ApplicTemplatesAppRoutes} />

      {/* <ApplicsAppRoutes /> */}
      <Route path='/applications' component={ApplicsAppRoutes} />

      {/* <SegmentsAppRoutes /> */}
      <Route path='/segments/shared' component={SegmentsAppRoutes} />

      {/* <SubjectsAppRoutes /> */}
      <Route
        path='/institutions/settings/subjects'
        component={SubjectsAppRoutes}
      />

      {/* <StudyPlanAppRoutes /> */}
      <Route
        path='/affairs/classes/:classId/study_plan'
        component={StudyPlanAppRoutes}
      />

      {/* <ContentPagesAppRoutes /> */}
      <Route
        path={Object.values(ContentPageRoutePaths)}
        component={ContentPagesAppRoutes}
      />

      {/* Ensures that the catch-all route is the final one */}
      <Route path={aCustomPath} component={ContentPagesAppRoutes} />
    </Switch>
  )
}
