import React, { useCallback, useEffect, useRef } from 'react'
import EmailEditor from 'react-email-editor'
import { useQuery } from 'react-query'

import { utils } from '@fullfabric/public-api'

import FullScreenLoading from 'shared/components/FullScreenLoading'
import { useAppData } from 'shared/contexts/AppData'
import usePusher from 'shared/hooks/usePusher'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import { onDesignSaveDebounced } from '../../utils/onDesignSave'
import { uploadImage } from '../../utils/uploadImage'

import styles from './styles.module.scss'

export default function UnlayerEditor({ emailTemplate, onSave, onChange }) {
  const { config, _handle: handle, _user: user } = useAppData()
  const mergeTags = useMergeTags()
  const emailEditorRef = useRef(null)
  const [loadingEditor, setLoadingEditor] = React.useState(true)

  const assetsPath =
    process.env.NODE_ENV === 'development'
      ? `http://${handle}.localhost:3001/assets`
      : config.assets.prefix

  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )

  const features = {}
  if (colors.length > 0) {
    features.colorPicker = { presets: colors.map((color) => `#${color}`) }
  }

  usePusher({
    channelName: 'tus-uploads-attachments',
    binds: ['local-upload:success', 'remote-upload:success'],
    opts: { tenant: true },
    onMessage: ({ bind, data }) => {
      notifyUnlayer(
        bind === 'local-upload:success' ? 'uploading' : 'upload',
        data
      )
    }
  })

  const { data, isLoading } = useQuery(['emailTemplateInfo'], async () => {
    const response = await fetch(`/api/email_templates/editor_info`, {
      credentials: 'same-origin'
    })
    return utils.checkResponse(response)
  })

  const onDesignUpdate = useCallback(() => {
    onChange()
    if (emailEditorRef.current?.editor) {
      onDesignSaveDebounced(emailEditorRef.current?.editor, onSave)
    }
  }, [onChange, onSave])

  const notifyUnlayer = (type, data) => {
    emailEditorRef.current.editor.frame.iframe.contentWindow.postMessage(
      { event: `fullfabric:${type}`, data },
      '*'
    )
  }

  const onReady = (unlayer) => {
    unlayer.addEventListener('design:loaded', onDesignUpdate)
    unlayer.registerCallback('image', async (file, done) => {
      done({ progress: 10 })
      const { url } = await uploadImage(emailTemplate.id, file)

      done({ progress: 100, url })
    })
    unlayer.loadDesign(emailTemplate.design)

    notifyUnlayer('data', {
      host: window.location.origin,
      attachments: emailTemplate.attachments,
      templateId: emailTemplate.id,
      channelName: `${handle}-tus-uploads-attachments`,
      userToken: user.token
    })
    setLoadingEditor(false)
  }

  useEffect(() => {
    emailEditorRef.current?.editor?.addEventListener(
      'design:updated',
      onDesignUpdate
    )

    return () => {
      emailEditorRef.current?.editor?.removeEventListener(
        'design:loaded',
        onDesignUpdate
      )

      // eslint-disable-next-line react-hooks/exhaustive-deps
      emailEditorRef.current?.editor?.removeEventListener(
        'design:updated',
        onDesignUpdate
      )
    }
  }, [onDesignUpdate])

  return (
    <div className={styles.editorContainer}>
      {(loadingEditor || isLoading) && (
        <div className={styles.loadingContainer}>
          <FullScreenLoading />
        </div>
      )}
      {!isLoading && (
        <EmailEditor
          minHeight='100%'
          style={{
            display: loadingEditor ? 'none' : 'block'
          }}
          ref={emailEditorRef}
          onReady={onReady}
          options={{
            displayMode: 'email',
            designMode: 'live',
            projectId: data.project_id,
            user: data.user,
            mergeTags,
            customJS: [
              `${assetsPath}/vendor/app/v5/email.js`,
              `${assetsPath}/vendor/app/v5/tus.js`
            ],
            features
          }}
        />
      )}
    </div>
  )
}
