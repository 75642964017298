import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import getStaffProfiles from 'apps/Reports/api/getStaffProfiles'
import getThemes from 'apps/Reports/api/getThemes'
import useProductPerformanceColumns from 'apps/Reports/hooks/useProductPerformanceColumns'
import ChartTypeButton from './ChartTypeButton'
import {
  getCapacityOptions,
  getPeriodOptions,
  getProductStateOptions
} from './constants'
import LinkedClassesSubTable from './LinkedClassesSubTable'

import styles from './styles.module.scss'

const STORAGE_FILTER_KEY = 'product_performance:filter:'

const ProductPerformanceTable = ({
  baseClassId,
  chartType,
  onChartTypeChange,
  onFilterChanged,
  onSearchChanged,
  onSortChanged
}) => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  const columns = useProductPerformanceColumns({ chartType })

  return (
    <div
      className={styles.positioner}
      role='region'
      aria-label={
        baseClassId
          ? t('courses in class data display and manipulation region')
          : t('data display and manipulation region')
      }
    >
      <ChartTypeButton onChangeChartType={onChartTypeChange} />
      <AutoloadDataTable
        // base table props
        fixedLayout
        virtualized
        columns={columns}
        emptyMessage={t('No data')}
        ExpandedRowComponent={LinkedClassesSubTable}
        // fetch and pagination props
        fetchURL={'/api/reports/product_performance'}
        fetchParams={baseClassId ? { class_of_id: baseClassId } : {}}
        reactQueryProps={{
          staleTime: 0,
          refetchOnWindowFocus: false // otherwise closes expanded rows on window focus
        }}
        loadMoreProps={{
          sizeKey: 'limit',
          offsetKey: 'offset',
          pageSize: 50
        }}
        fetchResponseParser={(response) => {
          const pagination = response.headers['x-fullfabric-json']
            ? JSON.parse(response.headers['x-fullfabric-json'])
            : {}

          return { ...pagination, data: response.data }
        }}
        // sorting props
        sortable
        sortParams={{
          sortKey: 'sort[field]',
          directionKey: 'sort[direction]',
          ascValue: 1,
          descValue: -1
        }}
        initialSort={{
          'sort[field]': 'starts_on',
          'sort[direction]': 1
        }}
        onSortChanged={onSortChanged}
        // search props
        searchable
        searchParamKey='search'
        onSearchChanged={onSearchChanged}
        // filter props
        filterable
        filters={[
          {
            id: 'state',
            name: t('Class/course state'),
            type: 'multi',
            options: getProductStateOptions(t)
          },
          {
            id: 'owner_ids',
            name: t('Owner'),
            type: 'multi',
            searchField: 'label',
            loadOptions: getStaffProfiles
          },
          {
            id: 'capacity',
            name: t('Capacity'),
            type: 'multi',
            options: getCapacityOptions(t)
          },
          {
            id: 'theme',
            name: t('Theme'),
            type: 'multi',
            loadOptions: getThemes,
            valueField: 'id',
            labelField: 'name',
            searchField: 'name'
          },
          {
            id: 'period',
            name: t('Class/course start date'),
            type: 'single',
            options: getPeriodOptions(t)
          }
        ]}
        onFilterChanged={onFilterChanged}
        filterStorageKey={STORAGE_FILTER_KEY + user?.id}
      />
    </div>
  )
}

export default ProductPerformanceTable
